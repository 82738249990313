import { action, makeObservable, observable, toJS } from "mobx";
import {
  createTournament,
  updateTournament,
  assignActiveTourneyByEmail,
  getPlayersByTournamentId,
  getScoresByID,
  getMatchesByTournamentId,
  getNamesByEmails,
  updatePlayerAllFields,
  deleteActiveTourneyById,
  sendCustomEmail,
  deleteMatch,
  deleteScore,
  updatePlayerListByTournamentId,
  updateScore,
  updateMatch,
  assignNewActiveTourneyByEmail,
  getMatchesByTournamentIdAndRound,
  addPlayOffsToTournament,
  getPlayoffsMatchesByTournamentId,
  updateLeagueNameAndChampion,
  removePlayerFromTournament,
  updateDateFromPlayer,
} from "../services/firebase";
import { Messages } from "../helpers/messages";
import { toast } from "react-toastify";
import { getMessages } from "../helpers/getMessages";
import type { FirebaseError } from "firebase/app";
import TournamentModel, { TournamentStatus } from "../models/Tournament";
import type {
  IStep1InputElement,
  IRulesInputElement,
} from "../helpers/getTournamentFields";
import type {
  ITournament,
  IGroup,
  IPlayer,
  ITournamentGroup,
  statisticsPlayer,
  standingsType,
} from "../models/Tournament";
import PlayerModel, { ITournamentPlayer } from "../models/Player";
import { IMatch, IMatchResults } from "../models/Match";
import { convertDateToMoment, convertMomentDate } from "../helpers/convertDate";
import { getCourseDetail } from "../services/courses";

class TournamentViewModel {
  tournament: TournamentModel = new TournamentModel();
  author = "";
  idTournament = "";
  standings: Array<standingsType> = [];
  dogfightStats: Array<{
    id: string;
    name: string;
    gross: number;
    handicap: number;
    net: string;
  }> = [];
  newStats: {
    [key: string]: {
      [key: string]: Array<{
        player: string;
        date: string;
        hdc: string;
        net: number;
        score: string;
        course: number;
        points: string;
        opponent: string;
      }>;
    };
  } = {};
  statsPlayers: Array<statisticsPlayer> = [];
  statsTeams: Array<{
    name: string;
    playersNames: string;
    roundsPlayed: number;
    pointsPerPlayer?: string;
    points: number;
  }> = [];
  fullStatsTeams: {
    [key: string]: ITournamentPlayer[];
  } = {};
  conferencesOptions: Array<{ value: string; label: string }> = [];
  groupsOptions: Array<{ value: string; label: string }> = [];
  leagueResults: Array<IMatch> = [];
  playOffsResults: Array<IMatch> = [];
  matrizValues: {
    leagueName: string;
    data: {
      [key: string]: {
        [key: string]: Array<{
          gross: number;
          hcp: number;
          score: number;
          date: string;
          net: number;
        }>;
      };
    };
    names: {
      conferenceName: string;
      results: { [key: string]: string };
    }[];
  } = {
    leagueName: "",
    data: {},
    names: [],
  };
  playersResultsOptions: Array<{ label: string; value: string }> = [];

  constructor() {
    makeObservable(this, {
      tournament: observable,
      author: observable,
      newStats: observable,
      idTournament: observable,
      setTournament: action,
      createTournament: action,
      updateTournament: action,
      saveEmailList: action,
      getTournamentValues: action,
      getAuthor: action,
      getTournamentName: action,
      getEmailList: action,
      addEmailToList: action,
      editEmailList: action,
      removeEmailFromList: action,
      getPlayers: action,
      updateGroupList: action,
      updateConferencesList: action,
      updateTeamList: action,
      updateGroupPlayers: action,
      updateConferenceGroup: action,
      updateTeamPlayers: action,
      getGroups: action,
      getPlayersPerGroup: action,
      getStatsPlayersByTournament: action,
      startTournament: action,
      statsPlayers: observable,
      dogfightStats: observable,
      statsTeams: observable,
      fullStatsTeams: observable,
      conferencesOptions: observable,
      groupsOptions: observable,
      getAllMatchesResultsByTournament: action,
      leagueResults: observable,
      playOffsResults: observable,
      playersResultsOptions: observable,
      updatePlayersAndMatches: action,
      deleteLeague: action,
      deleteMatch: action,
      switchPlayer: action,
      removePlayerFromTournament: action,
      getNewStats: action,
      setStandingsBytTournament: action,
    });
    this.statsPlayers = [];
    this.dogfightStats = [];
  }

  setTournament(tournament: Partial<ITournament>): void {
    // console.log(JSON.stringify({...tournament}, null, 2));
    // console.log(JSON.stringify({...this.tournament}, null, 2));
    // console.log(JSON.stringify({...this.tournament, ...tournament}, null, 2));
    this.tournament = { ...this.tournament, ...tournament };
  }

  setAuthor(id: string): void {
    this.author = id;
  }

  getAuthor(): string {
    return this.author;
  }

  setTournamentId(id: string): void {
    this.idTournament = id;
    this.tournament.id = id;
  }

  getTournamentValues(): IStep1InputElement {
    return {
      name: this.tournament.name,
      type: this.tournament.tournamentType,
      players: this.tournament.players,
      playType: this.tournament.playType,
    };
  }

  getRulesValues(): IRulesInputElement {
    return {
      playoffs: this.tournament.playOffs,
      startDate: this.tournament.startDate,
      cutOffDate: this.tournament.cutOffDate,
      matchesPerRound: toJS(this.tournament.matchesPerRound),
      pointsPerTie: this.tournament.pointsPerTie || 1,
      pointsPerWin: this.tournament.pointsPerWin || 3,
      pointsPerTieMedal: this.tournament.pointsPerTieMedal || 1,
      pointsPerWinMedal: this.tournament.pointsPerWinMedal || 3,
      numberOfRounds: this.tournament.numberOfRounds || 1,
      roundDates: toJS(this.tournament.roundDates) || [],
      championshipRound: this.tournament.championshipRound || false,
      championshipDate: this.tournament.championshipDate,
      minRounds: this.tournament.minRounds || 1,
      numberOfStages: this.tournament.numberOfStages || 1,
      stagesDates: toJS(this.tournament.stagesDates) || [],
    };
  }

  getTournamentName(): string {
    return this.tournament.name;
  }

  saveEmailList(): void {
    this.updateTournament(toJS(this.tournament));
  }
  addEmailToList(email: string, name: string): void {
    this.tournament.playersList.push({
      name,
      email: email.toLowerCase(),
      id: email,
    });
    if (this.tournament.playersPerGroup.length > 0) {
      const id = `${email}-${this.tournament.playersList.length - 1}`;
      this.tournament.playersPerGroup[0].players.push({ name, email, id });
      this.tournament.teams[0].players.push({ name, email, id });
    }
  }

  editEmailList(email: string, name: string, index: number): void {
    this.tournament.playersList[index] = {
      ...this.tournament.playersList[index],
      name,
      email: email.toLowerCase(),
    };
  }

  removeEmailFromList(key: number): void {
    const newEmailList = [...this.tournament.playersList];
    newEmailList.splice(key, 1);
    this.tournament.playersList = newEmailList;
  }

  getEmailList(): Array<Partial<IPlayer>> {
    return this.tournament.playersList;
  }

  getPlayers(): number {
    return this.tournament.players;
  }
  getGroups(): number {
    return this.tournament.groups;
  }

  getPlayersPerGroup(): Array<IGroup> {
    return this.tournament.playersPerGroup;
  }

  updatePlayersPerGroup(groups: IGroup[]): void {
    this.tournament.playersPerGroup = groups;
    this.tournament.groups = groups.length - 1;
    this.updateTournament(toJS(this.tournament));
  }

  updateGroupList(newGroups: Array<ITournamentGroup>): void {
    this.tournament.groupsList = newGroups;
  }
  updateConferencesList(newConferences: Array<ITournamentGroup>): void {
    this.tournament.conferencesList = newConferences;
  }
  updateTeamList(newTeams: Array<ITournamentGroup>): void {
    this.tournament.teamsList = newTeams;
  }
  updateGroupPlayers(group: string, playerId: string) {
    this.tournament.playersList = this.tournament.playersList.map((player) => {
      if (player.id === playerId) {
        player.group = group;
        const conference = this.tournament.groupsList.find(
          (gr) => gr.id === group
        );
        if (conference) {
          player.conference = conference.conference;
        }
      }
      return player;
    });
  }

  updateConferenceGroup(conference: string, groupId: string) {
    this.tournament.groupsList = this.tournament.groupsList.map((group) => {
      if (group.id === groupId) {
        group.conference = conference;
      }
      return group;
    });
    this.tournament.playersList = this.tournament.playersList.map((player) => {
      if (player.group === groupId) {
        player.conference = conference;
      }
      return player;
    });
  }

  updateTeamPlayers(team: string, playerId: string) {
    this.tournament.playersList = this.tournament.playersList.map((player) => {
      if (player.id === playerId) {
        player.team = team;
      }
      return player;
    });
  }

  updateTeams(teams: IGroup[]): void {
    this.tournament.teams = teams;
    this.updateTournament(toJS(this.tournament));
  }

  updateConference(conference: IGroup[]): void {
    this.tournament.conference = conference;
    this.updateTournament(toJS(this.tournament));
  }

  startTournament(): void {
    this.tournament.status = TournamentStatus.PUBLISHED;
    // Send email to all players

    // Add tourney to user's list
    this.tournament.playersList.forEach((player) => {
      const nPlayer = new PlayerModel({
        name: player.name || "",
        email: player.email || "",
        group: player.group || "",
        team: player.team || "",
        conference: player.conference || "",
        tournamentId: this.idTournament || "",
      });

      assignActiveTourneyByEmail(
        player.email || "",
        this.idTournament,
        this.tournament.name,
        toJS(nPlayer)
      );
    });
    //this.updateTournament(toJS(this.tournament));
  }

  async addPlayOffsDetails(
    numberOfPlayers: number,
    previousPlayers: { [key: string]: string },
    matches: { [key: string]: string[] }
  ): Promise<void> {
    console.log(toJS(this.tournament));
    //if (this.tournament.playOffs) {
    this.tournament.playOffsDetail.players = numberOfPlayers;
    this.tournament.playOffsDetail.brackets = previousPlayers;
    this.tournament.playOffsDetail.matches = matches;
    await addPlayOffsToTournament(
      this.idTournament,
      this.tournament.playOffsDetail
    );
    //}
  }

  async getAllMatchesResultsByTournament(): Promise<void> {
    const players = (await getPlayersByTournamentId(this.idTournament)) || [];
    if (players.length === 0) {
      return;
    }
    const emails = players.map((player) => player.email);
    const names = await getNamesByEmails(emails);
    const listEmails = names?.reduce((acc, curr) => {
      acc[curr.email] = curr.name + " " + curr.lastName;
      return acc;
    }, {} as { [key: string]: string });
    this.playersResultsOptions = players
      .map((player) => ({
        label: listEmails ? listEmails[player.email] : player.name,
        value: player.email,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    const matches = await getMatchesByTournamentId(this.idTournament);
    const playOffsMatches = await getPlayoffsMatchesByTournamentId(
      this.idTournament
    );
    console.log(toJS(matches), "matches");
    const getPointsPerMatch = (
      idPlayer: string,
      idOpponent: string,
      result: IMatchResults
    ) => {
      const player = players.find((p) => p.email === idPlayer);

      const isTeamPlay = this.tournament.tournamentType === "teamplay";
      if (isTeamPlay) {
        const matchPoints = result.matchPoints || 0;
        const medalPoints = result.medalPoints || 0;
        const teamPoints = result.teamPoints || 0;
        return { matchPoints, medalPoints, teamPoints };
      }
      const opponentIndex =
        player?.opponent.findIndex((id) => id === idOpponent) || 0;

      const matchPoints = player?.pointsMatch[opponentIndex] || 0;
      const medalPoints = player?.pointsStroke[opponentIndex] || 0;
      const teamPoints = player?.pointsTeam[opponentIndex] || 0;
      const bonusPoints =
        player?.bonusPoints !== undefined
          ? player?.bonusPoints[opponentIndex] || 0
          : 0;
      return { matchPoints, medalPoints, teamPoints, bonusPoints };
    };

    this.leagueResults = matches.map((match) => ({
      ...match,
      matchResults: match.matchResults.map((result) => ({
        ...result,
        ...getPointsPerMatch(
          result.idPlayer,
          match.matchResults.find((r) => r.idPlayer !== result.idPlayer)
            ?.idPlayer || "",
          result
        ),
        playerName: listEmails
          ? listEmails[result.idPlayer]
          : result.playerName,
      })),
    }));

    this.playOffsResults = playOffsMatches.map((match) => ({
      ...match,
      matchResults: match.matchResults.map((result) => ({
        ...result,
        ...getPointsPerMatch(
          result.idPlayer,
          match.matchResults.find((r) => r.idPlayer !== result.idPlayer)
            ?.idPlayer || "",
          result
        ),
        playerName: listEmails
          ? listEmails[result.idPlayer]
          : result.playerName,
      })),
    }));

    console.log("playOffsResults", toJS(this.playOffsResults));

    // Crear la constante matrizValues
    this.matrizValues = {
      leagueName: "League Name",
      data: {},
      names: [],
    };

    this.matrizValues.names = listEmails
      ? Object.keys(listEmails).reduce((acc, curr) => {
          const conference =
            players.find((p) => p.email === curr)?.conference || "";
          if (acc.find((a) => a.conferenceName === conference)) {
            const index = acc.findIndex((a) => a.conferenceName === conference);
            acc[index].results[curr] = listEmails[curr];
          } else {
            acc.push({
              conferenceName: conference,
              results: {
                [curr]: listEmails[curr],
              },
            });
          }
          return acc;
        }, [] as { conferenceName: string; results: { [key: string]: string } }[])
      : [];

    console.log(toJS(this.matrizValues), "matrizValues");
    console.log(toJS(this.leagueResults), "this.leagueResults");

    const isTeamPlay = this.tournament.tournamentType === "teamplay";
    if (!isTeamPlay) {
      // Iterar sobre leagueResults para llenar this.matrizValues
      this.leagueResults.forEach((match) => {
        const playerEmail = match.matchResults[0];
        const opponentEmail = match.matchResults[1];

        if (!this.matrizValues.data[playerEmail.idPlayer]) {
          this.matrizValues.data[playerEmail.idPlayer] = {};
        }

        if (!this.matrizValues.data[opponentEmail.idPlayer]) {
          this.matrizValues.data[opponentEmail.idPlayer] = {};
        }

        this.matrizValues.data[playerEmail.idPlayer][opponentEmail.idPlayer] =
          [];
        this.matrizValues.data[opponentEmail.idPlayer][playerEmail.idPlayer] =
          [];

        this.matrizValues.data[playerEmail.idPlayer][
          opponentEmail.idPlayer
        ].push({
          gross: opponentEmail.gross || 0,
          hcp: parseInt(opponentEmail.hcp) || 0,
          score: opponentEmail.score,
          date: convertMomentDate(match.date),
          net: opponentEmail.medalPoints || 0,
        });
        this.matrizValues.data[playerEmail.idPlayer][
          opponentEmail.idPlayer
        ].push({
          gross: playerEmail.gross || 0,
          hcp: parseInt(playerEmail.hcp) || 0,
          score: playerEmail.score,
          date: convertMomentDate(match.date),
          net: playerEmail.medalPoints || 0,
        });

        this.matrizValues.data[opponentEmail.idPlayer][
          playerEmail.idPlayer
        ].push({
          gross: playerEmail.gross || 0,
          hcp: parseInt(playerEmail.hcp) || 0,
          score: playerEmail.score,
          date: convertMomentDate(match.date),
          net: playerEmail.medalPoints || 0,
        });
        this.matrizValues.data[opponentEmail.idPlayer][
          playerEmail.idPlayer
        ].push({
          gross: opponentEmail.gross || 0,
          hcp: parseInt(opponentEmail.hcp) || 0,
          score: opponentEmail.score,
          date: convertMomentDate(match.date),
          net: opponentEmail.medalPoints || 0,
        });
      });
    }
  }

  async changeMatchDate(matchId: string, newDate: string): Promise<void> {
    const dates = convertDateToMoment(newDate);
    const dateFormatted = convertMomentDate(dates);
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    const match = this.leagueResults.find((m) => m.id === matchId);
    if (match) {
      console.log(toJS(match), "match");
      const newMatch = { ...match, date: dates };
      const previousDate = convertMomentDate(match.date);
      console.log(newMatch, "newMatch");
      console.log(dateFormatted, "dateFormatted");
      console.log(previousDate, "previousDate");
      const emailLists = match.matchResults.map((m) => m.idPlayer);
      console.log(emailLists, "emailLists");
      await updateMatch(matchId, newMatch);

      await updateDateFromPlayer(
        previousDate,
        dateFormatted,
        emailLists,
        this.idTournament
      );

      // const players = (await getPlayersByTournamentId(match.tournamentId)) || [];
      // for (const player of match.matchResults) {
      //   const playerData = players.find((p) => p.email === player.idPlayer);
      //   if (playerData) {
      //     const index = playerData.date?.findIndex(
      //       (d) => d === previousDate
      //     );
      //     const newScore = {
      //       ...playerData,
      //       date: playerData.date?.map((d, i) =>
      //         i === index ? dateFormatted : d
      //       ),
      //     };
      //     console.log(playerData, "playerData");
      //     console.log(newScore, "newScore");
      //     //await updateScore(player.scoresId, newScore);
      //   }
      //}
    }
    const displayMessage = getMessages(Messages.DATE_CHANGED);
    toast.update(cuToast, {
      render: displayMessage,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 800,
    });
  }

  async deleteMatch(matchId: string): Promise<void> {
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    const match = this.leagueResults.find((m) => m.id === matchId);
    const tournamentId = match?.tournamentId || "";
    const scoreId1 = match?.scoresId[0] || "";
    const scoreId2 = match?.scoresId[1] || "";
    const players = (await getPlayersByTournamentId(tournamentId)) || [];
    const p1 = match?.matchResults[0].idPlayer || "";
    const p2 = match?.matchResults[1].idPlayer || "";
    const player1 = players.find((p) => p.email === p1);
    const player2 = players.find((p) => p.email === p2);
    const index1 = player1?.scoreId.findIndex((o) => o === scoreId1) || 0;
    const index2 = player2?.scoreId.findIndex((o) => o === scoreId2) || 0;
    if (!player1 || !player2) {
      return;
    }
    const newPlayer1 = { ...player1 };
    const newPlayer2 = { ...player2 };
    newPlayer1.opponent = player1?.opponent.filter((o, i) => i !== index1);
    newPlayer2.opponent = player2?.opponent.filter((o, i) => i !== index2);
    newPlayer1.pointsMatch = player1?.pointsMatch?.filter(
      (o, i) => i !== index1
    );
    newPlayer2.pointsMatch = player2?.pointsMatch?.filter(
      (o, i) => i !== index2
    );
    newPlayer1.pointsStroke = player1?.pointsStroke?.filter(
      (o, i) => i !== index1
    );
    newPlayer2.pointsStroke = player2?.pointsStroke?.filter(
      (o, i) => i !== index2
    );
    newPlayer1.pointsTeam = player1?.pointsTeam?.filter((o, i) => i !== index1);
    newPlayer2.pointsTeam = player2?.pointsTeam?.filter((o, i) => i !== index2);
    newPlayer1.gross = player1?.gross?.filter((o, i) => i !== index1);
    newPlayer2.gross = player2?.gross?.filter((o, i) => i !== index2);
    newPlayer1.handicap = player1?.handicap?.filter((o, i) => i !== index1);
    newPlayer2.handicap = player2?.handicap?.filter((o, i) => i !== index2);
    newPlayer1.net = player1?.net?.filter((o, i) => i !== index1);
    newPlayer2.net = player2?.net?.filter((o, i) => i !== index2);
    newPlayer1.scoreId = player1?.scoreId?.filter((o, i) => i !== index1);
    newPlayer2.scoreId = player2?.scoreId?.filter((o, i) => i !== index2);
    newPlayer1.id = player1?.id || "";
    newPlayer2.id = player2?.id || "";

    await updatePlayerAllFields(newPlayer1);
    await updatePlayerAllFields(newPlayer2);
    await deleteScore(scoreId1);
    await deleteScore(scoreId2);
    await deleteMatch(matchId);

    const displayMessage = getMessages(Messages.MATCH_DELETED);
    toast.update(cuToast, {
      render: displayMessage,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 800,
    });
  }

  async deleteRound(matchId: string, playerId: string): Promise<void> {
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    const match = this.leagueResults.find((m) => m.id === matchId);

    const playerIndexToRemove = match?.matchResults.findIndex(
      (m) => m.idPlayer === playerId
    );

    const scoreID = playerIndexToRemove
      ? match?.scoresId[playerIndexToRemove]
      : "";
    const newMatch = {
      ...match,
      matchResults: match?.matchResults.filter((m) => m.idPlayer !== playerId),
      scoresId: match?.scoresId.filter((s, i) => i !== playerIndexToRemove),
    };
    if (scoreID && scoreID !== "") {
      await deleteScore(scoreID);
    }
    if (matchId && newMatch) {
      await updateMatch(matchId, newMatch);
    }

    const displayMessage = getMessages(Messages.ROUND_DELETED);
    toast.update(cuToast, {
      render: displayMessage,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 800,
    });
  }

  async removePlayerFromMatch(
    matchId: string,
    playerId: string
  ): Promise<void> {
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    const match = this.leagueResults.find((m) => m.id === matchId);

    const playerIndexToRemove = match?.matchResults.findIndex(
      (m) => m.idPlayer === playerId
    );

    const scoreID = playerIndexToRemove
      ? match?.scoresId[playerIndexToRemove]
      : "";
    const newMatch = {
      ...match,
      matchResults: match?.matchResults.filter((m) => m.idPlayer !== playerId),
      scoresId: match?.scoresId.filter((s, i) => i !== playerIndexToRemove),
    };
    console.log(toJS(newMatch), "newMatch");
    console.log(scoreID, "scoreID");

    if (scoreID && scoreID !== "") {
      await deleteScore(scoreID);
    }
    if (matchId && newMatch) {
      await updateMatch(matchId, newMatch);
    }

    const displayMessage = getMessages(Messages.PLAYER_REMOVED);
    toast.update(cuToast, {
      render: displayMessage,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 800,
    });
  }

  async removePlayerFromTournament(playerId: string): Promise<void> {
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);

    await removePlayerFromTournament(playerId, this.idTournament);

    const displayMessage = "Player Removed";
    toast.update(cuToast, {
      render: displayMessage,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 800,
    });
  }

  async switchPlayer(prevId: string, newId: string, newName: string) {
    const players = (await getPlayersByTournamentId(this.idTournament)) || [];
    const player = players.find((p) => p.email === prevId);
    if (!player) {
      return;
    }
    const tournamentId = this.tournament.id || "";
    const newPlayer = {
      ...player,
      email: newId,
      name: newName,
      prevEmail: prevId,
    };

    console.log(toJS(player), toJS(newPlayer), "player");
    await updatePlayerAllFields(newPlayer);
    if (prevId !== newId) {
      await assignNewActiveTourneyByEmail(
        newPlayer.email || "",
        player.email || "",
        tournamentId,
        this.tournament.name,
        toJS(newPlayer)
      );
    }

    const newPlayers = this.tournament.playersList.map((p) => {
      if (p.email === prevId) {
        return { ...p, email: newId, name: newName, id: newId };
      }
      return toJS(p);
    });

    await updatePlayerListByTournamentId(tournamentId || "", newPlayers);

    for (const opponent of newPlayer.opponent) {
      const player = players.find((p) => p.email === opponent);
      if (player) {
        const newPlayer = {
          ...player,
          opponent: player?.opponent.map((o) => (o === prevId ? newId : o)),
        };
        await updatePlayerAllFields(newPlayer);
        await getMatchesByTournamentId(tournamentId).then(async (matches) => {
          for (const match of matches) {
            let updated = false;
            const newMatch = {
              ...match,
              matchResults: match.matchResults.map((m) => {
                if (m.idPlayer === prevId) {
                  updated = true;
                  return { ...m, idPlayer: newId, playerName: newName };
                }
                return m;
              }),
            };
            if (updated) {
              await updateMatch(match.id || "", newMatch);
            }
          }
        });
      }
    }

    for (const scoreId of newPlayer.scoreId) {
      const score = await getScoresByID(scoreId);
      if (score) {
        const newScore = {
          ...score,
          idPlayer: score.idPlayer === prevId ? newId : score.idPlayer,
          player: score.idPlayer === prevId ? newName : score.player,
        };
        await updateScore(scoreId, newScore);
      }
    }
  }

  async updatePlayersAndMatches(changes: {
    [key: string]: { [key: string]: string };
  }): Promise<void> {
    const players = (await getPlayersByTournamentId(this.idTournament)) || [];

    const newPlayers: { [key: string]: ITournamentPlayer } = {};
    const matches = [];
    for (const i in changes) {
      const m = i.split("-");
      const p1 = m[0];
      const p2 = m[1];
      if (!newPlayers[p1]) {
        newPlayers[p1] = players.filter((e) => e.email === p1)[0];
      }
      if (!newPlayers[p2]) {
        newPlayers[p2] = players.filter((e) => e.email === p2)[0];
      }
      matches.push(i.split("-"));
      const index1 = newPlayers[p1].opponent.findIndex((o) => o === p2);
      const index2 = newPlayers[p2].opponent.findIndex((o) => o === p1);

      if (newPlayers[p1].bonusPoints === undefined) {
        newPlayers[p1].bonusPoints = newPlayers[p1].pointsMatch.map(() => 0);
      }
      if (newPlayers[p2].bonusPoints === undefined) {
        newPlayers[p2].bonusPoints = newPlayers[p2].pointsMatch.map(() => 0);
      }

      // console.log("matchpoints1", changes[i].matchpoints1);
      // console.log("medalPoints1", changes[i].medalPoints1);

      if (changes[i].matchpoints1 !== undefined) {
        newPlayers[p1].pointsMatch[index1] = parseInt(changes[i].matchpoints1);
        newPlayers[p2].pointsMatch[index2] = parseInt(changes[i].matchpoints2);
      }
      if (changes[i].medalPoints1 !== undefined) {
        newPlayers[p1].pointsStroke[index1] = parseInt(changes[i].medalPoints1);
        newPlayers[p2].pointsStroke[index2] = parseInt(changes[i].medalPoints2);
      }
      newPlayers[p1].pointsTeam[index1] = parseInt(changes[i].teampoints1);
      newPlayers[p2].pointsTeam[index2] = parseInt(changes[i].teampoints2);
      newPlayers[p1].bonusPoints[index1] = parseInt(changes[i].bonuspoints1);
      // console.log(parseInt(changes[i].bonuspoints1));
      // console.log(
      //   newPlayers[p1].bonusPoints[index1],
      //   "newPlayers[p1].bonusPoints[index1]"
      // );
      newPlayers[p2].bonusPoints[index2] = parseInt(changes[i].bonuspoints2);
    }

    console.log(newPlayers, "newPlayers");
    for (const np in newPlayers) {
      await updatePlayerAllFields(newPlayers[np]);
    }
  }

  async getPlayersOfChampionshipRound(): Promise<void> {
    this.dogfightStats = [];
    const matches = await getMatchesByTournamentId(this.idTournament);
    const matchesByRound: { [key: number]: IMatchResults[] } = matches
      .filter((match) => match.round !== 0)
      .reduce((acc, curr) => {
        if (curr.round) {
          if (acc[curr.round]) {
            acc[curr.round] = [...acc[curr.round], ...curr.matchResults];
          } else {
            acc[curr.round] = [...curr.matchResults];
          }
        }
        return acc;
      }, {} as { [key: number]: IMatchResults[] }); // Add index signature to the type
    const hashMapPlayersWithRoundsData = Object.keys(matchesByRound).reduce(
      (acc, curr) => {
        const playersPerRound = matchesByRound[parseInt(curr)];
        playersPerRound.forEach((player) => {
          console.log(player, "player    '''''''");
          if (acc[player.idPlayer]) {
            acc[player.idPlayer] = [...acc[player.idPlayer], player];
          } else {
            acc[player.idPlayer] = [player];
          }
        });
        return acc;
      },
      {} as { [key: string]: IMatchResults[] }
    ); // Add index signature to the type
    const emails = Object.keys(hashMapPlayersWithRoundsData).map(
      (player) => player
    );
    const names = (await getNamesByEmails(emails)) || []; // Optimized name fetching
    const nameMap = new Map(
      names.map((player) => [player.email, player.name + " " + player.lastName])
    );
    const players = Object.keys(hashMapPlayersWithRoundsData).map((player) => ({
      name: nameMap.get(player) || player,
      id: player,
      data: hashMapPlayersWithRoundsData[player],
    }));

    const getAverage = (values: Array<number>) => {
      const average = values.reduce((acc, curr) => acc + curr, 0);
      return average > 0 ? (average / values.length).toFixed(1) : "0";
    };

    this.dogfightStats = players
      .filter((player) => player.data.length >= this.tournament.minRounds)
      .map((player) => {
        const playerData = {
          id: player.id,
          name: player.name.toString(), // Fix: Convert the 'name' property to a string
          gross: parseInt(getAverage(player.data.map((d) => d.gross))), // player.data.reduce((acc, curr) => acc + curr.gross, 0),
          handicap: parseInt(
            getAverage(player.data.map((d) => parseInt(d.hcp)))
          ),
          net: getAverage(player.data.map((d) => d.score)).toString(),
        };
        return playerData;
      })
      .sort((a, b) => {
        if (parseInt(a.net) > 0 && parseInt(b.net) > 0) {
          return parseInt(b.net) - parseInt(a.net);
        } else if (parseInt(a.net) <= 0 && parseInt(b.net) <= 0) {
          return 0;
        } else {
          return parseInt(a.net) - parseInt(b.net);
        }
      })
      .reverse();
  }

  async getPlayersPerRound(round: number): Promise<void> {
    this.dogfightStats = [];
    const matches = await getMatchesByTournamentIdAndRound(
      this.idTournament,
      round
    );
    const fullPlayers = await getPlayersByTournamentId(this.idTournament);
    const emails = fullPlayers.map((player) => player.email);

    console.log(fullPlayers, emails, "fullPlayers");
    // Use a more efficient approach for large datasets:
    const names = (await getNamesByEmails(emails)) || []; // Optimized name fetching
    const nameMap = new Map(
      names.map((player) => [player.email, player.name + " " + player.lastName])
    );

    const players = fullPlayers.map((player) => ({
      ...player,
      name: nameMap.get(player.email) || player.name,
    }));
    const newPlayers = matches.map((match) => match.matchResults).flat();
    this.dogfightStats = newPlayers
      .map((player) => {
        const playerStats = players.find((p) => p.email === player.idPlayer);
        const playerData = {
          id: player.idPlayer,
          name: playerStats?.name || "",
          gross: player.gross,
          handicap: Number(player.hcp), // Convert the handicap to a number
          net: player.score.toString(),
        };
        return playerData;
      })
      .sort((a, b) => {
        if (parseInt(a.net) > 0 && parseInt(b.net) > 0) {
          return parseInt(b.net) - parseInt(a.net);
        } else if (parseInt(a.net) <= 0 && parseInt(b.net) <= 0) {
          return 0;
        } else {
          return parseInt(a.net) - parseInt(b.net);
        }
      })
      .reverse();
  }

  async setStandingsBytTournament(standings: standingsType[]): Promise<void> {
    this.standings = standings;
  }

  async getStatsPlayersByTournament(): Promise<void> {
    const t1 = performance.now();

    const tournamentType = this.tournament.tournamentType;

    const playType = this.tournament.playType;
    const pointsPerTie = this.tournament.pointsPerTie;
    const pointsPerWin = this.tournament.pointsPerWin;
    const pointsPerTieMedal = this.tournament.pointsPerTieMedal;
    const pointsPerWinMedal = this.tournament.pointsPerWinMedal;

    const isLTMATCH =
      tournamentType === "leagueteamplay" && playType === "matchplaystableford";
    const isLTMEDAL =
      tournamentType === "leagueteamplay" && playType === "medalplaystableford";
    const isLMATCH = tournamentType === "league" && playType === "matchPlay";
    const isLMEDAL = tournamentType === "league" && playType === "strokePlay";

    const isDogFight = tournamentType === "dogfight";

    // Fetch players and optimize data fetching
    const fullPlayers = await getPlayersByTournamentId(this.idTournament);
    const emails = fullPlayers.map((player) => player.email);

    // Use a more efficient approach for large datasets:
    const names = (await getNamesByEmails(emails)) || []; // Optimized name fetching
    const nameMap = new Map(
      names.map((player) => [player.email, player.name + " " + player.lastName])
    );

    const players = fullPlayers.map((player) => ({
      ...player,
      name: nameMap.get(player.email) || player.name,
    }));

    // Fetch scores in batches for improved performance
    const newPlayers = await Promise.all(
      players.map(async (player) => {
        const getAverage = (values: Array<number>) => {
          const average = values.reduce((acc, curr) => acc + curr, 0);
          return average > 0 ? (average / values.length).toFixed(1) : "0";
        };

        const getWins = () => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerWin ? acc + 1 : acc),
              0
            );
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerWinMedal ? acc + 1 : acc),
              0
            );
          }
          return (
            player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerWin ? acc + 1 : acc),
              0
            ) +
            player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerWinMedal ? acc + 1 : acc),
              0
            )
          );
        };

        const getDraws = () => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerTie ? acc + 1 : acc),
              0
            );
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerTieMedal ? acc + 1 : acc),
              0
            );
          }
          return (
            player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerTie ? acc + 1 : acc),
              0
            ) +
            player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerTieMedal ? acc + 1 : acc),
              0
            )
          );
        };

        const getLoss = () => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            );
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            );
          }
          return (
            player.pointsMatch.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            ) +
            player.pointsStroke.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            )
          );
        };

        const getBonusPoints = () =>
          player.bonusPoints
            ? player.bonusPoints.reduce((acc, curr) => acc + curr, 0)
            : 0;

        const getTotalPoints = () => {
          if (isLTMATCH || isLMATCH) {
            return (
              player.pointsMatch.reduce((acc, curr) => acc + curr, 0) +
              getBonusPoints()
            );
          }
          if (isLTMEDAL || isLMEDAL) {
            return (
              player.pointsStroke.reduce((acc, curr) => acc + curr, 0) +
              getBonusPoints()
            );
          }
          return (
            player.pointsMatch.reduce((acc, curr) => acc + curr, 0) +
            player.pointsStroke.reduce((acc, curr) => acc + curr, 0) +
            getBonusPoints()
          );
        };

        return {
          id: Number(player.id),
          position: 0, // Add position property
          tourneyName: player.name, // Add tourneyName property
          matchesPlayed:
            playType !== "matchstrokePlay"
              ? player.opponent.length
              : player.opponent.length * 2,
          wins: getWins(),
          draws: getDraws(),
          losses: getLoss(),
          bonusPoints: getBonusPoints(),
          matchPoints: player.pointsMatch.reduce((acc, curr) => acc + curr, 0),
          medalPoints: player.pointsStroke.reduce((acc, curr) => acc + curr, 0),
          totalPoints: getTotalPoints(),
          grossAverage: getAverage(
            player.gross.map((value) => parseInt(value.toString()))
          ),
          handicapAverage: getAverage(
            player.handicap.map((value) => parseInt(value.toString()))
          ),
          netAverage: getAverage(
            player.net.map((value) => parseInt(value.toString()))
          ),
          teamPoints: player.pointsTeam.reduce((acc, curr) => acc + curr, 0),
          conference: player.conference,
          group: player.group,
        };
      })
    );

    // Sort players by total points
    this.statsPlayers = !isDogFight
      ? newPlayers.sort((a, b) => b.totalPoints - a.totalPoints)
      : newPlayers
          .sort((a, b) => {
            if (parseInt(a.netAverage) > 0 && parseInt(b.netAverage) > 0) {
              return parseInt(b.netAverage) - parseInt(a.netAverage);
            } else if (
              parseInt(a.netAverage) <= 0 &&
              parseInt(b.netAverage) <= 0
            ) {
              return 0;
            } else {
              return parseInt(a.netAverage) - parseInt(b.netAverage);
            }
          })
          .reverse();

    // ... rest of the function logic (with potential optimizations for data structure usage and caching)
    if (players) {
      const teams: { [key: string]: Array<ITournamentPlayer> } =
        players?.reduce((acc, curr) => {
          const team = curr.team;
          if (!acc[team]) {
            acc[team] = [];
          }
          acc[team].push(curr);
          return acc;
        }, {} as { [key: string]: Array<ITournamentPlayer> });

      const finalTeams = [];
      for (const team in teams) {
        const teamName =
          this.tournament.teamsList.find((t) => t.id === team)?.name || "";
        const roundsPlayed = teams[team].reduce(
          (acc, curr) => acc + curr.pointsTeam.length,
          0
        );
        const points = teams[team].reduce(
          (acc, curr) =>
            acc + curr.pointsTeam.reduce((acc, curr) => acc + curr, 0),
          0
        );
        const newTeam = {
          name: teamName,
          playersNames: teams[team].map((player) => player.name).join(", "),
          roundsPlayed,
          points,
        };
        finalTeams.push(newTeam);
      }
      this.statsTeams = finalTeams.sort((a, b) => b.points - a.points);
      this.conferencesOptions = this.tournament.conferencesList.map(
        (conference) => ({
          value: conference.id,
          label: conference.name,
        })
      );
      this.groupsOptions = this.tournament.groupsList.map((group) => ({
        value: group.id,
        label: group.name,
      }));
    }
    const t2 = performance.now();
    console.log("Call to NEW took" + (t2 - t1) + "milliseconds.");
  }

  async getTeamStatsPlayersByTournament(): Promise<void> {
    const t1 = performance.now();

    console.log(
      "getTeamStatsPlayersByTournament",
      "this.tournament",
      toJS(this.tournament)
    );

    const tournamentType = this.tournament.tournamentType;

    const playType = this.tournament.playType;
    const pointsPerTie = this.tournament.pointsPerTie;
    const pointsPerWin = this.tournament.pointsPerWin;
    const pointsPerTieMedal = this.tournament.pointsPerTieMedal;
    const pointsPerWinMedal = this.tournament.pointsPerWinMedal;

    const isLTMATCH =
      tournamentType === "leagueteamplay" && playType === "matchPlay";
    const isLTMEDAL =
      tournamentType === "leagueteamplay" && playType === "strokePlay";
    const isLMATCH = tournamentType === "league" && playType === "matchPlay";
    const isLMEDAL = tournamentType === "league" && playType === "strokePlay";

    const isDogFight = tournamentType === "dogfight";

    // Fetch players and optimize data fetching
    const fullPlayers = await getPlayersByTournamentId(this.idTournament);
    console.log("fullPlayers", fullPlayers);
    const emails = fullPlayers.map((player) => player.email);

    // Use a more efficient approach for large datasets:
    const names = (await getNamesByEmails(emails)) || []; // Optimized name fetching
    const nameMap = new Map(
      names.map((player) => [player.email, player.name + " " + player.lastName])
    );

    const players = fullPlayers.map((player) => ({
      ...player,
      name: nameMap.get(player.email) || player.name,
    }));

    // Fetch scores in batches for improved performance
    const newPlayers = await Promise.all(
      players.map(async (player) => {
        const getAverage = (values: Array<number>) => {
          const average = values.reduce((acc, curr) => acc + curr, 0);
          return average > 0 ? (average / values.length).toFixed(1) : "0";
        };

        const getWins = () => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerWin ? acc + 1 : acc),
              0
            );
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerWinMedal ? acc + 1 : acc),
              0
            );
          }
          return (
            player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerWin ? acc + 1 : acc),
              0
            ) +
            player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerWinMedal ? acc + 1 : acc),
              0
            )
          );
        };

        const getDraws = () => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerTie ? acc + 1 : acc),
              0
            );
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerTieMedal ? acc + 1 : acc),
              0
            );
          }
          return (
            player.pointsMatch.reduce(
              (acc, curr) => (curr === pointsPerTie ? acc + 1 : acc),
              0
            ) +
            player.pointsStroke.reduce(
              (acc, curr) => (curr === pointsPerTieMedal ? acc + 1 : acc),
              0
            )
          );
        };

        const getLoss = () => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            );
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            );
          }
          return (
            player.pointsMatch.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            ) +
            player.pointsStroke.reduce(
              (acc, curr) => (curr === 0 ? acc + 1 : acc),
              0
            )
          );
        };

        const getTotalPoints = () => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch.reduce((acc, curr) => acc + curr, 0);
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke.reduce((acc, curr) => acc + curr, 0);
          }
          return (
            player.pointsMatch.reduce((acc, curr) => acc + curr, 0) +
            player.pointsStroke.reduce((acc, curr) => acc + curr, 0)
          );
        };

        const getBonusPoints = () =>
          player.bonusPoints
            ? player.bonusPoints.reduce((acc, curr) => acc + curr, 0)
            : 0;

        return {
          id: Number(player.id),
          position: 0, // Add position property
          tourneyName: player.name, // Add tourneyName property
          matchesPlayed:
            playType !== "matchstrokePlay"
              ? player.opponent.length
              : player.opponent.length * 2,
          wins: getWins(),
          draws: getDraws(),
          losses: getLoss(),
          bonusPoints: getBonusPoints(),
          matchPoints: player.pointsMatch.reduce((acc, curr) => acc + curr, 0),
          medalPoints: player.pointsStroke.reduce((acc, curr) => acc + curr, 0),
          totalPoints: getTotalPoints(),
          grossAverage: getAverage(
            player.gross.map((value) => parseInt(value.toString()))
          ),
          handicapAverage: getAverage(
            player.handicap.map((value) => parseInt(value.toString()))
          ),
          netAverage: getAverage(
            player.net.map((value) => parseInt(value.toString()))
          ),
          teamPoints: player.pointsTeam.reduce((acc, curr) => acc + curr, 0),
          conference: player.conference,
          group: player.group,
        };
      })
    );

    // Sort players by total points
    this.statsPlayers = !isDogFight
      ? newPlayers.sort((a, b) => b.totalPoints - a.totalPoints)
      : newPlayers
          .sort((a, b) => {
            if (parseInt(a.netAverage) > 0 && parseInt(b.netAverage) > 0) {
              return parseInt(b.netAverage) - parseInt(a.netAverage);
            } else if (
              parseInt(a.netAverage) <= 0 &&
              parseInt(b.netAverage) <= 0
            ) {
              return 0;
            } else {
              return parseInt(a.netAverage) - parseInt(b.netAverage);
            }
          })
          .reverse();

    // ... rest of the function logic (with potential optimizations for data structure usage and caching)
    if (players) {
      const teams: { [key: string]: Array<ITournamentPlayer> } =
        players?.reduce((acc, curr) => {
          const team = curr.team;
          if (!acc[team]) {
            acc[team] = [];
          }
          acc[team].push(curr);
          return acc;
        }, {} as { [key: string]: Array<ITournamentPlayer> });

      const finalTeams = [];
      for (const team in teams) {
        const teamName =
          this.tournament.teamsList.find((t) => t.id === team)?.name || "";
        const roundsPlayed = teams[team].reduce(
          (acc, curr) => acc + curr.pointsTeam.length,
          0
        );
        const points = teams[team].reduce(
          (acc, curr) =>
            acc + curr.pointsTeam.reduce((acc, curr) => acc + curr, 0),
          0
        );
        console.log(toJS(teams[team]), "teams[team]");
        const newTeam = {
          name: teamName,
          playersNames: teams[team].map((player) => player.name).join(", "),
          pointsPerPlayer: teams[team]
            .map((player) => player.pointsTeam)
            .join(", "),
          roundsPlayed,
          points,
        };
        finalTeams.push(newTeam);
      }
      this.fullStatsTeams = teams;
      this.statsTeams = finalTeams.sort((a, b) => b.points - a.points);
      this.conferencesOptions = this.tournament.conferencesList.map(
        (conference) => ({
          value: conference.id,
          label: conference.name,
        })
      );
      this.groupsOptions = this.tournament.groupsList.map((group) => ({
        value: group.id,
        label: group.name,
      }));
    }
    const t2 = performance.now();
    console.log("Call to NEW took" + (t2 - t1) + "milliseconds.");
  }

  async getNewStats(): Promise<void> {
    const tournamentType = this.tournament.tournamentType;

    const playType = this.tournament.playType;
    const pointsPerTie = this.tournament.pointsPerTie;
    const pointsPerWin = this.tournament.pointsPerWin;
    const pointsPerTieMedal = this.tournament.pointsPerTieMedal;
    const pointsPerWinMedal = this.tournament.pointsPerWinMedal;

    const isLTMATCH =
      tournamentType === "leagueteamplay" && playType === "matchplaystableford";
    const isLTMEDAL =
      tournamentType === "leagueteamplay" && playType === "medalplaystableford";
    const isLMATCH = tournamentType === "league" && playType === "matchPlay";
    const isLMEDAL = tournamentType === "league" && playType === "strokePlay";

    const isDogFight = tournamentType === "dogfight";

    // Fetch players and optimize data fetching
    const fullPlayers = await getPlayersByTournamentId(this.idTournament);
    console.log("fullPlayers", fullPlayers);
    const emails = fullPlayers.map((player) => player.email);

    // Use a more efficient approach for large datasets:
    const names = (await getNamesByEmails(emails)) || []; // Optimized name fetching
    const nameMap = new Map(
      names.map((player) => [player.email, player.name + " " + player.lastName])
    );

    const matches = await getMatchesByTournamentId(this.idTournament);

    const players = fullPlayers.map((player) => ({
      ...player,
      name: nameMap.get(player.email) || player.name,
    }));

    const pl: {
      [key: string]: {
        [key: string]: Array<{
          player: string;
          date: string;
          hdc: string;
          net: number;
          score: string;
          course: number;
          points: string;
          opponent: string;
        }>;
      };
    } = {};

    matches.forEach((match) => {
      match.matchResults.forEach((player, i) => {
        const id = player.idPlayer;
        const moreData = players.find((p) => p.email === id);
        console.log(moreData, "moreData");
        const currentPosition = moreData?.scoreId.findIndex(
          (s) => s === match.scoresId[i]
        );
        const currentConference = moreData?.conference || "";

        const getTotalPoints = (player: {
          pointsMatch: number;
          pointsStroke: number;
          bonusPoints: number;
        }) => {
          if (isLTMATCH || isLMATCH) {
            return player.pointsMatch + player.bonusPoints;
          }
          if (isLTMEDAL || isLMEDAL) {
            return player.pointsStroke + player.bonusPoints;
          }
          return player.pointsMatch + player.pointsStroke + player.bonusPoints;
        };
        const values = {
          player: moreData?.name || "",
          date: moreData?.date ? moreData?.date[currentPosition || 0] : "",
          hdc: player.hcp,
          net:
            player.score -
            getCourseDetail(match.teeBox).par.reduce(
              (acc, curr) => acc + curr,
              0
            ),
          score: match.scoresId[i],
          points: getTotalPoints({
            pointsMatch: moreData?.pointsMatch[currentPosition || 0] || 0,
            pointsStroke: moreData?.pointsStroke[currentPosition || 0] || 0,
            bonusPoints:
              moreData?.bonusPoints !== undefined
                ? moreData?.bonusPoints[currentPosition || 0] || 0
                : 0,
          }).toString(),
          course: getCourseDetail(match.teeBox).par.reduce(
            (acc, curr) => acc + curr,
            0
          ),
          opponent:
            nameMap.get(moreData?.opponent[currentPosition || 0] || "") || "",
        };
        if (!pl[currentConference]) {
          pl[currentConference] = {} as {
            [key: string]: Array<{
              player: string;
              date: string;
              hdc: string;
              net: number;
              score: string;
              course: number;
              points: string;
              opponent: string;
            }>;
          };
        }
        if (pl[currentConference][id]) {
          pl[currentConference][id].push(values);
        } else {
          pl[currentConference][id] = [values];
        }
      });
    });
    this.newStats = pl;
  }

  async sendEmail(): Promise<void> {
    sendCustomEmail(
      "jeckox@gmail.com",
      "Envio Firebase George",
      "Hola Envio Correo"
    );
  }

  async createTournament(tournament: Partial<ITournament>): Promise<void> {
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    try {
      const tournamentId = await createTournament({
        ...tournament,
        author: this.author,
      });

      this.setTournamentId(tournamentId);
      this.setTournament(tournament);
      const displayMessage = getMessages(Messages.TOURNAMENT_CREATED);
      toast.update(cuToast, {
        render: displayMessage,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 800,
      });
    } catch (error) {
      const codeError = (error as FirebaseError).code;
      const displayError = getMessages(codeError);
      toast.update(cuToast, {
        render: displayError,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 800,
      });
    }
  }

  async updateTournament(tournament: Partial<ITournament>): Promise<void> {
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    try {
      const thisTournament = toJS(this.tournament);
      await updateTournament(this.idTournament, {
        ...thisTournament,
        ...tournament,
        author: this.author,
      });

      this.setTournament({
        ...thisTournament,
        ...tournament,
        author: this.author,
      });
      const displayMessage = getMessages(Messages.TOURNAMENT_UPDATED);
      toast.update(cuToast, {
        render: displayMessage,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 800,
      });
    } catch (error) {
      const codeError = (error as FirebaseError).code;
      const displayError = getMessages(codeError);
      toast.update(cuToast, {
        render: displayError,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 800,
      });
    }
  }

  async deleteLeague(): Promise<void> {
    await deleteActiveTourneyById(this.idTournament);
    location.reload();
  }

  async finishLeague(leagueName: string, championName: string): Promise<void> {
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    const emails = this.tournament.playersList.map((e) => e.email || "");
    const isTeamPlay = this.tournament.tournamentType === "teamplay";
    const title = isTeamPlay
      ? `${leagueName} comes to an end`
      : `${leagueName} season wrap up`;
    const body = isTeamPlay
      ? `
    <p>Dear Golfers,</p>
    <p>Thank you all for an amazing season! With several stages behind us and countless impressive performances, we're thrilled to announce Team <b>${championName}</b> as this year’s champions! Your dedication and skill have earned you a well-deserved victory.</p>
    <p>To all our players, thanks for bringing the fun (and sometimes frustration) to every round. Whether you were crushing drives or just making sure the golf carts stayed in one piece, you made this season unforgettable. We’re thrilled to have been along for the ride—fore-sure!
    </p>
    <p>As a bonus, you can now access the Team & Player Championship Leaderboards under the <b>TeeBox League History</b> tab to relive the highlights (and perhaps a few near-misses).</p>
    <p>We look forward to many more rounds and a new season filled with great golf and even greater company.</p>
    <p>Best,</p>
    <p>The TeeBox League Team</p>
    `
      : `
    <p>Fore!</p>
    <p>The ${leagueName} season has officially come to a close, and what a season it’s been! Huge thanks to everyone who participated, whether you were sinking birdies or stuck in the rough, you all made this league a hole-in-one.</p>
    <p>A special shout-out to <b>${championName}</b>, who swung their way to the top and claimed the champion’s crown. Time to update that trophy shelf!</p>
    <p>For those looking to relive the glory (or, ahem, some of those "almost" shots), head over to your <b>History</b> tab and under the champ's name you can find:</p>
    <ul>
    <li>All <b>Match Results</b></li>
    <li>The <b>Full Leaderboard</b></li>
    <li>The <b>Playoff Bracket</b></li>
    </ul>
    <p>Thanks again for bringing your A-game this season. We’ll see you back on the green next time—because every round is just one swing away from greatness (or the bunker)!</p>
    <p>Keep it on the fairway,</p>
    <p>The Tee Box League Team</p>
    `;
    await updateLeagueNameAndChampion(
      this.idTournament,
      leagueName,
      championName
    );

    //await updateHistoryLeagues(emails, this.idTournament);

    await sendCustomEmail(emails, title, body);
    toast.update(cuToast, {
      render: "League Finished",
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 800,
    });
    setTimeout(() => {
      location.reload();
    }, 3000);
    //location.reload();
  }
}

export default TournamentViewModel;
